import { Swiper, SwiperSlide } from "swiper/vue";
import ArrowSvg from "@/assets/images/ArrowSvg.vue";
import windowResize from "@/assets/mixins/windowResize";

import ProjectSvg from "@/assets/images/mobile/burger/ProjectSvg.vue";
import HowToPlaySvg from "@/assets/images/mobile/burger/HowToPlaySvg.vue";
import FaqSvg from "@/assets/images/mobile/burger/FaqSvg.vue";
import RequirementsSvg from "@/assets/images/mobile/burger/RequirementsSvg.vue";
import ShopSvg from "@/assets/images/mobile/burger/ShopSvg.vue";
import MenuArrowSvg from "@/assets/images/mobile/burger/ArrowSvg.vue";

export default {
  name: "HomePage",
  mixins: [windowResize],
  components: {
    ArrowSvg,
    Swiper,
    SwiperSlide,
    ProjectSvg,
    HowToPlaySvg,
    FaqSvg,
    RequirementsSvg,
    ShopSvg,
    MenuArrowSvg,
  },

  pros: [
    {
      icon: "audience",
      title: "Аудитория",
      description: "Самая адекватная аудитория в комьюнити среди всех игровых проектов в Криминальной России",
    },
    {
      icon: "voice",
      title: "Голосовой чат",
      description: "Самый удобный и качественныйВживайся в роль и общайся с другими игроками по микрофону",
    },
    {
      icon: "vehicles",
      title: "Реальный автопарк",
      description: "По улицам русских городов и деревень ездят не только иностранные, но и отечественные автомобили",
    },
    {
      icon: "graphic",
      title: "Графика",
      description: "Значительно отличается от других проектов, а улучшенная графика позволяет сделать вид игры как GTA V",
    },
    {
      icon: "atmosphere",
      title: "Атмосфера",
      description: "Удивительный мир и ощущение атмосферы, которой больше нигде нет. Мы шли к этому с 2015 года",
    },
    {
      icon: "rp",
      title: "Уровень отыгровки",
      description: "Удивительный мир и ощущение атмосферы, которой больше нигде нет. Мы шли к этому с 2015 года",
    },
  ],

  CARDS: [
    {
      image: "police",
      title: "Страж закона",
      description: "Следует отметить, что сплочённость команды профессионалов говорит о возможностях анализа существующих паттернов поведения.",
    },
    {
      image: "burglar",
      title: "Бандит",
      description: "В частности, перспективное планирование в значительной степени обусловливает важность первоочередных требований.",
    },
    {
      image: "mafia",
      title: "Босс мафии",
      description: "Однозначно, тщательные исследования конкурентов заблокированы в рамках своих собственных рациональных ограничений.",
    },
    {
      image: "businessman",
      title: "Бизнесмен",
      description: "Для современного мира выбранный нами инновационный путь предопределяет высокую востребованность первоочередных требований.",
    },
  ],

  STEPS: [
    {
      title: "Первый шаг",
      description: "Скачайте лицензионную версию Grand Theft Auto V",
    },

    {
      title: "Второй шаг",
      description: "Установите Rage Multiplayer, для игры на нашем севрере",
    },

    {
      title: "Третий шаг",
      description: "Скопируйте наш адрес и вставьте в лаунчер мультиплеера, что бы подключится к серверу",
    },
  ],

  FAQ: [
    [
      {
        title: "Когда откроется проект?",
        description:
          "В своём стремлении повысить качество жизни, они забывают, что понимание сути ресурсосберегающих технологий однозначно определяет каждого участника как способного принимать собственные решения касаемо благоприятных перспектив.",
      },

      {
        title: "Когда откроется проект?",
        description:
          "В своём стремлении повысить качество жизни, они забывают, что понимание сути ресурсосберегающих технологий однозначно определяет каждого участника как способного принимать собственные решения касаемо благоприятных перспектив.",
      },

      {
        title: "Когда откроется проект?",
        description:
          "В своём стремлении повысить качество жизни, они забывают, что понимание сути ресурсосберегающих технологий однозначно определяет каждого участника как способного принимать собственные решения касаемо благоприятных перспектив.",
      },

      {
        title: "Когда откроется проект?",
        description:
          "В своём стремлении повысить качество жизни, они забывают, что понимание сути ресурсосберегающих технологий однозначно определяет каждого участника как способного принимать собственные решения касаемо благоприятных перспектив.",
      },

      {
        title: "Когда откроется проект?",
        description:
          "В своём стремлении повысить качество жизни, они забывают, что понимание сути ресурсосберегающих технологий однозначно определяет каждого участника как способного принимать собственные решения касаемо благоприятных перспектив.",
      },
    ],
    [
      {
        title: "Когда откроется проект?",
        description:
          "В своём стремлении повысить качество жизни, они забывают, что понимание сути ресурсосберегающих технологий однозначно определяет каждого участника как способного принимать собственные решения касаемо благоприятных перспектив.",
      },

      {
        title: "Когда откроется проект?",
        description:
          "В своём стремлении повысить качество жизни, они забывают, что понимание сути ресурсосберегающих технологий однозначно определяет каждого участника как способного принимать собственные решения касаемо благоприятных перспектив.",
      },

      {
        title: "Когда откроется проект?",
        description:
          "В своём стремлении повысить качество жизни, они забывают, что понимание сути ресурсосберегающих технологий однозначно определяет каждого участника как способного принимать собственные решения касаемо благоприятных перспектив.",
      },

      {
        title: "Когда откроется проект?",
        description:
          "В своём стремлении повысить качество жизни, они забывают, что понимание сути ресурсосберегающих технологий однозначно определяет каждого участника как способного принимать собственные решения касаемо благоприятных перспектив.",
      },

      {
        title: "Когда откроется проект?",
        description:
          "В своём стремлении повысить качество жизни, они забывают, что понимание сути ресурсосберегающих технологий однозначно определяет каждого участника как способного принимать собственные решения касаемо благоприятных перспектив.",
      },
    ],
  ],

  REQUIREMENTS: [
    {
      title: "Минимальные",
      list: [
        {
          icon: "windows",
          text: "Windows 8.1 64 Bit, Windows 8 64 Bit, Windows 7 64 Bit Service Pack 1",
        },

        {
          icon: "core",
          text: "Intel Core 2 Q6600 @ 2.40 Ггц (четырехъядерный) / AMD Phenom 9850 (четырехъядерный) @ 2.5 Ггц",
        },

        {
          icon: "RAM",
          text: "4 GB ОЗУ",
        },

        {
          icon: "GPU",
          text: "NVIDIA 9800 GT c 1 Гб видеопамяти/ AMD HD 4870 с 1 Гб видеопамяти (DX 10, 10.1, 11)",
        },

        {
          icon: "SSD",
          text: "72 GB",
        },
      ],
    },

    {
      title: "Рекомендованые",
      list: [
        {
          icon: "windows",
          text: "Windows 8.1 64 Bit, Windows 8 64 Bit, Windows 7 64 Bit Service Pack 1",
        },

        {
          icon: "core",
          text: "Intel Core i5 3470 @ 3.2 Ггц (четырехъядерный) / AMD X8 FX-8350 @ 4 Ггц (восьмиядерный)",
        },

        {
          icon: "RAM",
          text: "8 GB ОЗУ",
        },

        {
          icon: "GPU",
          text: "NVIDIA GTX 660 с 2 Гб видеопамяти / AMD HD7870 с 2 Гб видеопамяти",
        },

        {
          icon: "SSD",
          text: "72 GB",
        },
      ],
    },
  ],

  FOOTER: [
    {
      title: "Навигация",
      links: [
        { title: "О проекте", link: "#proj" },
        { title: "Как начать играть", link: "#play" },
        { title: "Ответы на вопросы", link: "#faq" },
        { title: "Требования к системе", link: "#requirements" },
      ],
    },

    {
      title: "Полезные ссылки",
      links: [
        { title: "Правила", link: "" },
        { title: "Инструкция установки", link: "" },
        { title: "Обратная связь", link: "" },
      ],
    },
  ],

  BURGER_MENU: [
    { icon: "ProjectSvg", title: "О проекте", href: "#proj" },
    { icon: "HowToPlaySvg", title: "Как начать играть?", href: "#play" },
    { icon: "FaqSvg", title: "Ответы на вопросы", href: "#faq" },
    { icon: "RequirementsSvg", title: "Требования к системе", href: "#requirements" },
    { icon: "ShopSvg", title: "Магазин", href: "#shop" },
  ],

  MEDIA: [
    { icon: "youtube", link: "" },
    { icon: "discord", link: "" },
    { icon: "vk", link: "" },
  ],

  data() {
    return {
      previewSwiper: null,
      activeFAQIndex: null,
      isMenuOpened: false,
    };
  },

  methods: {
    previewSliderNext() {
      this.previewSwiper.slideNext();
    },

    previewSliderPrevious() {
      this.previewSwiper.slidePrev();
    },

    registerPreviewSwiper(swiper) {
      this.previewSwiper = swiper;
    },

    setFAQIndex(index) {
      if (this.activeFAQIndex !== index) this.activeFAQIndex = index;
      else this.activeFAQIndex = null;
    },

    openMobileBurgerMenu(status) {
      if (screen.availWidth <= this.mobileWidth) {
        this.isMenuOpened = status;
      }
    },
  },
};

<div class="home column-block">
  <img class="logo" src="@/assets/images/logo.svg" alt="" />

  <div class="menu-button" @click="openMobileBurgerMenu">
    <img class="menu-button__picture" src="@/assets/images/menu.svg" alt="" />
  </div>

  <div class="burger-menu column-block align-end" :class="{ 'opened-menu': isMenuOpened }" @click.self="openMobileBurgerMenu(false)">
    <div class="burger-menu-panel column-block full-height">
      <div class="cross-block" @click="openMobileBurgerMenu(false)">
        <img src="@/assets/images/mobile/cross.svg" class="cross-block__picture" alt="" />
      </div>

      <div class="list column-block full-width">
        <a v-for="item of $options.BURGER_MENU" :href="item.href" class="list-item row-block full-width align-center">
          <component :is="item.icon" class="list-item__picture" />
          <span class="list-item__title">{{ item.title }}</span>

          <MenuArrowSvg class="list-item__picture-arrow" />
        </a>
      </div>

      <div class="social-media column-block">
        <span class="social-media__title">Подпишись на наши соцсети, что бы следить за прогрессом разработки и узнавать новости первым</span>

        <div class="media-links row-block full-width justify-between">
          <a v-for="media of $options.MEDIA" class="media-link" :href="media.link">
            <img :src="require(`@/assets/icons/media/${media.icon}.svg`)" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>

  <main class="project-preview justify-center">
    <div class="man"></div>

    <div class="developing row-block align-center">
      <img class="developing__picture" src="@/assets/icons/wrench.svg" alt="" />
      <span class="developing__title">В разработке</span>
    </div>

    <a href="https://rage.mp/" target="_blank" class="ragemp row-block align-center">
      <img class="ragemp__picture" src="@/assets/images/ragemp.svg" alt="" />
      <span class="ragemp__title">RageMP</span>
    </a>

    <div class="logo-text column-block align-center">
      <img class="logo-text__picture" src="@/assets/images/logo_text.svg" alt="" />

      <div class="swiper-container row-block align-center">
        <div class="arrow" @click="previewSliderPrevious">
          <ArrowSvg />
        </div>

        <swiper :slides-per-view="1" :space-between="20" @swiper="registerPreviewSwiper">
          <swiper-slide>Slide 1</swiper-slide>
          <swiper-slide>Slide 2</swiper-slide>
          <swiper-slide>Slide 3</swiper-slide>
        </swiper>

        <div class="arrow" @click="previewSliderNext">
          <ArrowSvg />
        </div>
      </div>
    </div>
  </main>

  <div class="preview-media row-block full-width align-center justify-between">
    <div class="media-links row-block align-center">
      <div class="media-link youtube">
        <img src="@/assets/images/youtube.svg" alt="" />
      </div>
      <div class="media-link discord">
        <img src="@/assets/images/discord.svg" alt="" />
      </div>
      <div class="media-link vk">
        <img src="@/assets/images/vk.svg" alt="" />
      </div>

      <div class="media-links__text">Подпишись на наши соцсети, что бы следить за прогрессом разработки и узнавать новости первым</div>
    </div>

    <a href="#proj" class="about-project row-block align-center">
      <ArrowSvg class="arrow" />
      <span class="about-project__title">Подробнее о проекте</span>
    </a>
  </div>

  <div class="pros full-width row-block">
    <div v-for="item of $options.pros" :class="item.icon" class="pros-container column-block">
      <img class="pros-container__icon" :src="require(`@/assets/icons/${ item.icon }.svg`)" alt="" />
      <span class="pros-container__title">{{ item.title }}</span>
      <span class="pros-container__text">{{ item.description }}</span>
    </div>
  </div>

  <div class="jobs column-block">
    <div id="proj" class="jobs-title row-block align-center">
      <img class="jobs-title__icon" src="@/assets/icons/arrows.svg" alt="" />
      <span class="jobs-title__title">Май лайф, май рулс...</span>
    </div>
    <div class="jobs-description">
      Мы вынуждены отталкиваться от того, что внедрение современных методик, а также свежий взгляд на привычные вещи — безусловно открывает новые
      горизонты для новых принципов формирования материально-технической и кадровой базы.
    </div>

    <div class="cards row-block full-width justify-between">
      <div v-for="card of $options.CARDS" class="card">
        <img :src="require(`@/assets/images/cards/${ card.image }.png`)" alt="" class="card__image" />
        <div class="text-container column-block">
          <span class="text-container__title">{{ card.title }}</span>
          <span class="text-container__description">{{ card.description }}</span>
        </div>
      </div>
    </div>

    <div class="attention row-block align-center">
      <div class="attention-button row-block">
        <img class="attention-button__icon" src="@/assets/icons/attention.svg" alt="" />
        <span class="attention-button__title">Актуальные новости</span>
      </div>

      <span class="attention__text">Это лишь малая часть, ты можешь сам решать кем стать</span>
    </div>
  </div>

  <div id="play" class="how-to-play column-block align-center">
    <div class="how-to-play-title row-block align-center full-width">
      <img class="how-to-play-title__icon" src="@/assets/icons/joystick.svg" alt="" />
      <span class="how-to-play-title__text">Как начать играть?</span>
    </div>

    <span class="how-to-play__description"
      >Мы вынуждены отталкиваться от того, что внедрение современных методик, а также свежий взгляд на привычные вещи — безусловно открывает новые
      горизонты для новых принципов формирования материально-технической и кадровой базы.</span
    >

    <span class="how-to-play__text-warning"
      >Проект находится в разработке, эта страница позволяет понять, что нужно для запуска игры. Всю информацию о старте работы проекта можно найти в
      наших социальных сетях и на сайте. Ни в коем случае ничего не скачиваете от незнакомых людей под предлогом поиграть на нашем проекте.
    </span>

    <div class="steps row-block full-width justify-between">
      <div v-for="(step, i) of $options.STEPS" class="step column-block">
        <span class="step__title">{{ step.title }}</span>
        <span class="step__description">{{ step.description }}</span>

        <template v-if="i === 0">
          <div class="links row-block">
            <div class="link align-center steam">
              <img src="@/assets/icons/steam.svg" alt="" class="link__image" />
              <span class="link__title">Steam</span>
            </div>

            <div class="link align-center epic-games">
              <img src="@/assets/icons/epic_games.svg" alt="" class="link__image" />
              <span class="link__title">Epic Games</span>
            </div>
          </div>

          <div class="hint">Можно приобрести игру по этим ссылкам, если у вас ее нет</div>
        </template>

        <template v-else-if="i === 1">
          <div class="links row-block">
            <div class="link align-center ragemp full-width">
              <img src="@/assets/icons/ragemp.svg" alt="" class="link__image" />
              <span class="link__title">Скачать</span>
            </div>
          </div>

          <div class="installation-guide row-block align-center">
            <img src="@/assets/icons/link-arrow.svg" alt="" class="installation-guide__image" />
            <span class="installation-guide__title">Полная инструкция установки</span>
          </div>
        </template>

        <template v-else-if="i === 2">
          <div class="links row-block">
            <div class="link align-center ip full-width">
              <span class="link__title">criminalrussia.gta5.net:22005</span>
              <img src="@/assets/icons/copy.svg" alt="" class="link__image" />
            </div>
          </div>
        </template>
      </div>
    </div>

    <img src="@/assets/images/steps/person.png" alt="" class="person" />
    <img src="@/assets/images/steps/person_shadow.png" alt="" class="person-shadow" />
  </div>

  <div id="faq" class="faq">
    <div class="faq-title row-block align-center">
      <img src="@/assets/icons/FAQ.svg" class="faq-title__image" alt="" />
      <span class="faq-title__title">Ответы на вопросы</span>
    </div>

    <div class="faq-description">
      Мы вынуждены отталкиваться от того, что внедрение современных методик, а также свежий взгляд на привычные вещи — безусловно открывает новые
      горизонты для новых принципов формирования материально-технической и кадровой базы.
    </div>

    <div class="columns row-block full-width">
      <div v-for="(column, i) of $options.FAQ" :key="`column-${i}`" class="column column-block full-width">
        <div
          v-for="(tile, j) of column"
          :key="`tile-${j}-${tile.title}`"
          class="tile column-block"
          :class="{ 'active': activeFAQIndex === i * column.length + j }"
        >
          <div class="tile-header row-block align-center full-width justify-between" @click="setFAQIndex(i * column.length + j)">
            <span class="tile-header__title">{{ tile.title }}</span>
            <img v-if="activeFAQIndex === i * column.length + j" class="tile-header__image" src="@/assets/icons/arrow-up.svg" alt="" />
            <img v-else class="tile-header__image" src="@/assets/icons/plus.svg" alt="" />
          </div>

          <div class="tile-description">{{ tile.description }}</div>
        </div>
      </div>
    </div>
  </div>

  <div id="requirements" class="system-requirements column-block">
    <div class="requirements-header row-block align-center">
      <img src="@/assets/icons/gear.svg" class="requirements-header__image" alt="" />
      <span class="requirements-header__title">Требования к системе</span>
    </div>

    <div class="system-requirements__description">Указанные требования взяты со страницы продукта в Steam и могут отличатся от фактических</div>

    <div class="lists row-block">
      <div v-for="list of $options.REQUIREMENTS" class="list-requirement column-block">
        <div class="list-requirement__title">{{ list.title }}</div>

        <div class="items column-block">
          <div v-for="item of list.list" class="item row-block align-center">
            <img :src="require(`@/assets/icons/${item.icon}.svg`)" class="item__picture" alt="" />
            <span class="item__title">{{ item.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer full-width column-block">
    <div class="footer-block row-block full-width justify-between">
      <div class="footer-container row-block">
        <div class="logo-container column-block">
          <img src="@/assets/icons/logo.svg" alt="" class="logo-container__picture" />
          <a class="logo-container__text" href="">Пользовательское соглашение</a>
          <a class="logo-container__text" href="">Политика конфиденциальности</a>
        </div>

        <div class="links row-block">
          <div v-for="container of $options.FOOTER" class="links-block column-block">
            <span class="links-block__title">{{ container.title }}</span>

            <div class="links-container column-block">
              <a v-for="link of container.links" :href="link.link" class="link-block">{{ link.title }}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="media row-block">
        <a v-for="media of $options.MEDIA" class="media-link" :href="media.link">
          <img :src="require(`@/assets/icons/media/${media.icon}.svg`)" alt="" />
        </a>
      </div>
    </div>

    <div class="warning">
      Криминальная Россия GTA V не связана и не одобрена Take-Two, Rockstar North Interactive или любым другим правообладателем. Все используемые
      товарные знаки принадлежат их соответствующим владельцам.
    </div>
  </div>
</div>

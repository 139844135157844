export default {
  mounted() {
    this.setInnerWidth();
    window.addEventListener("resize", this.setInnerWidth);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.setInnerWidth);
  },

  data() {
    return {
      innerWidth: 0,
      mobileWidth: 480,
    };
  },

  methods: {
    setInnerWidth() {
      this.innerWidth = screen.availWidth;
    },
  },

  computed: {
    isMobile() {
      return this.innerWidth <= this.mobileWidth;
    },
  },
};
